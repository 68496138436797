<template>
  <div class="registration">
    <div class="registration__title">
      Регистрация в системе
    </div>
    <div class="registration__form">
      <el-form
        :model="form"
        :rules="rules"
        ref="loginForm"
      >
        <div class="registration__form-row">
          <div class="registration__form-col">
            <el-form-item
                label="Имя"
                prop="name"
                :class="{ focused: isActiveInput === 'name' || form.name }"
                :show-message="false"
            >
              <el-input
                v-model="form.name"
                @focus="isActiveInput = 'name'"
                @blur="isActiveInput = ''"
              />
            </el-form-item>
            <el-form-item
                label="Email"
                prop="email"
                :class="{ focused: isActiveInput === 'email' || form.email }"
                :show-message="false"
            >
              <el-input
                v-model="form.email"
                @focus="isActiveInput = 'email'"
                @blur="isActiveInput = ''"
              />
            </el-form-item>
            <el-form-item
              label="Пароль"
              prop="password"
              :class="{ focused: isActiveInput === 'password' || form.password }"
              :show-message="false"
            >
              <el-input
                v-model="form.password"
                type="password"
                @focus="isActiveInput = 'password'"
                @blur="isActiveInput = ''"
              />
            </el-form-item>
            <el-form-item
              label="Повторить пароль"
              prop="password_confirmation"
              :class="{ focused: isActiveInput === 'password_confirmation' || form.password_confirmation }"
              :show-message="false"
            >
              <el-input
                v-model="form.password_confirmation"
                type="password"
                @focus="isActiveInput = 'password_confirmation'"
                @blur="isActiveInput = ''"
              />
            </el-form-item>
          </div>
          <div class="registration__form-col">
            <el-form-item
              label="Фамилия"
              prop="surname"
              :class="{ focused: isActiveInput === 'surname' || form.surname }"
            >
              <el-input
                v-model="form.surname"
                @focus="isActiveInput = 'surname'"
                @blur="isActiveInput = ''"
              />
            </el-form-item>
            <el-form-item
              label="Организация"
              prop="company"
              :class="{ focused: isActiveInput === 'company' || form.company }"
            >
              <el-input
                v-model="form.company"
                @focus="isActiveInput = 'company'"
                @blur="isActiveInput = ''"
              />
            </el-form-item>
            <el-form-item
              label="Должность"
              prop="position"
              :class="{ focused: isActiveInput === 'position' || form.position }"
            >
              <el-input
                v-model="form.position"
                @focus="isActiveInput = 'position'"
                @blur="isActiveInput = ''"
              />
            </el-form-item>
          </div>
        </div>
        <el-form-item prop="agree" class="registration__checkbox">
          <el-checkbox-group v-model="form.agree">
            <el-checkbox class="registration__form-link" name="agree">
              Принимаю условия <a href="/probit-user-agreement.pdf" target="_blank">Пользовательского соглашения</a> и <a href="/probit-privacy-policy.pdf" target="_blank">Политики конфиденциальности</a>
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div class="registration__form-button">
          <ButtonElement text="Регистрация" @click.prevent="registration('loginForm')"/>
        </div>
      </el-form>
    </div>
    <div class="registration__footer">
      Есть аккаунт? <router-link to="/login">Войти</router-link>
    </div>
  </div>
</template>

<script>
import ButtonElement from '../components/elements/ButtonElement'
import { AlertNotify } from '@/mixin/alert_notify'
import { ElMessage } from 'element-plus'

export default {
  name: 'Registration',
  mixins: [AlertNotify],
  components: {
    ButtonElement,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Введите пароль'));
      } else if(value.length < 6){
          callback(new Error('Пароль должен быть больше 6 символов'));
      }
      else {
        if (this.form.password_confirmation !== '') {
          this.$refs['loginForm'].validateField('checkPass');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Повтрите пароль'));
      } else if (value !== this.form.password) {
        callback(new Error('Пароли не совпадают'));
      } else {
        callback();
      }
    };
    return {
      isActiveInput: '',
      form: {
        name: '',
        surname: '',
        email: '',
        company: '',
        position: '',
        password: '',
        password_confirmation: '',
        agree: [],
      },
      rules: {
        name: [
          { required: true, trigger: 'blur' },
        ],
        email: [
          { required: true, trigger: 'blur' },
        ],
        password: [{ required: true, trigger: 'blur' }, { validator: validatePass, trigger: 'blur' }],
        password_confirmation: [{ required: true, trigger: 'blur' }, { validator: validatePass2, trigger: 'blur' }],
        agree: [
          { type: 'array', required: true, message: 'Подтвердите действие', trigger: 'change' }
        ],
      },
    }
  },

  methods:{
    registration (form) {
      this.$refs[form].validate(valid => {
        if(valid) {
          const data = this.form
          this.$store.dispatch('Auth/registration', data)
          .then(() => {
            ElMessage({
              message: 'Регистрация прошла успешно, пожалуйста войдите в аккаунт',
              type: 'success',
              duration: 10000
            })
            this.$router.push('/login')
          })
          .catch(err => {
            for (const key in err.response.data.errors) {
              ElMessage({
                message: err.response.data.errors[key][0],
                type: 'error',
                duration: 4000
              })
            }
          })
        } else {
          this.errorNotify('Проверьте правильность заполнениея полей')
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.registration {
  background: $basicWhite;
  box-shadow: 0 6px 64px rgba(0, 0, 0, 0.15);
  border-radius: 1.2rem;
  padding-top: 6rem;
  width: 76rem;

  @include below($md) {
    width: 90%;
    padding: 6rem 2rem 0;
  }

  &__title {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.5rem;
    text-align: center;
    margin-bottom: 3rem;
  }

  &__form {
    width: 58.5rem;
    margin: 0 auto 3.2rem;

    @include below($md) {
      width: 100%;
    }

    &-row {
      display: flex;
      justify-content: space-between;

      @include below($md) {
        flex-direction: column;
      }
    }

    &-col {
      width: 28rem;

      @include below($md) {
        width: 100%;
      }
    }

    &-link {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $darkGrey;

      a {
        color: $blue;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-button {
      display: flex;
      justify-content: center;
      margin-top: 2.5rem;
    }
  }

  &__footer {
    border-top: 1px solid $lightGrey;
    padding: 2.4rem 0;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $darkGrey;

    a {
      color: $blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
